
import axios from "axios";


console.log("REACT_APP_ENVIROMENT", process.env.REACT_APP_ENVIROMENT)
var API_ENDPOINT;
var IMAGE_ENDPOINT;
if (process.env.REACT_APP_ENVIROMENT === "staging") {
  API_ENDPOINT = 'https://app.homeandpaper.store';
  IMAGE_ENDPOINT = 'https://homeandpaper.s3.us-east-2.amazonaws.com/';
} else if (process.env.REACT_APP_ENVIROMENT === "production") {
  API_ENDPOINT = 'https://app.homeandpaper.store';
  IMAGE_ENDPOINT = 'https://homeandpaper.s3.us-east-2.amazonaws.com/';
} else {
  API_ENDPOINT = 'http://localhost:5000';
  IMAGE_ENDPOINT = 'https://homeandpaper.s3.us-east-2.amazonaws.com/';

}
export const IMAGE_URL = IMAGE_ENDPOINT
export const API_URL = API_ENDPOINT;


export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')

export const STATUS_OPTION = [{ label: "Active", value: "Active" }, { label: "De-Active", value: "De-Active" }]








export const uploadFileServer = async (data) => {
  try {
    var response = await axios.post(`/upload/s3`, data)
    return response.data.file
  } catch (err) {
    console.log("error", err)
    return null
  }

}
